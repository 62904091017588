<template>
    <div class="c-closet-badge">
        <CoreActionBadge
            :icon="'global--hanger'"
            :isSmall="isSmall"
            :isLoading="isLoading"
            :hidePadding="hidePadding"
            @click="toggleBadgeClicked"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import {
    LAUNCH_MODAL,
    MODALS_MODULE_NAME,
} from '~coreModules/modals/js/modals-store';
import { MODALS } from '~modules/modals/js/modal-constants';

import CoreActionBadge from '~coreModules/core/components/ui/badges/CoreActionBadge.vue';

export default {
    name: 'ClosetBadge',
    components: {
        CoreActionBadge,
    },
    props: {
        isSmall: {
            type: Boolean,
            default: false,
        },
        hidePadding: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    methods: {
        ...mapActions(MODALS_MODULE_NAME, {
            launchModal: LAUNCH_MODAL,
        }),
        toggleBadgeClicked() {
            this.launchModal(MODALS.closetJoinModal);
        },
    },
};
</script>

<style lang="scss">
.c-closet-badge {
    z-index: map-get($zindex, catalog-tile-badge);
}
</style>
