<template>
    <div class="c-loading-spinner"></div>
</template>

<script>
export default {
    name: 'LoadingSpinner',
};
</script>

<style lang="scss">
.c-loading-spinner {
    height: $nu-spacer-2;
    width: $nu-spacer-2;

    border: 1px solid;
    border-color: transparent currentcolor currentcolor currentcolor;
    border-radius: 50%;
    animation: spin 2s infinite linear;

    @keyframes spin {
        from { transform: rotate(0deg) }
        to { transform: rotate(360deg) }
    }
}
</style>
