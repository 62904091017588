<template>
    <div
        v-tab-focus="badgeClicked"
        class="c-action-badge o-flex-vertical-center o-flex-horizontal-center"
        :class="badgeClasses"
    >
        <div
            class="c-action-badge__fill-container"
            :data-product-tour-lightbox="productTourDataAttribute"
        >
            <span v-if="showCount" class="c-action-badge__counter o-text--caption">
                {{ count }}
            </span>
            <BaseIcon
                :icon="icon"
                :size="'16px'"
                :color="isActive ? 'white' : 'primary'"
                class="c-action-badge__icon"
            />
            <LoadingSpinner class="c-action-badge__loading-spinner" />
            <div class="c-action-badge__minus"></div>
        </div>
    </div>
</template>

<script>
import actionBadgeProps from '~coreModules/core/components/mixins/actionBadgeProps';
import LoadingSpinner from '~coreModules/core/components/ui/LoadingSpinner.vue';

export default {
    name: 'CoreActionBadge',
    components: {
        LoadingSpinner,
    },
    mixins: [actionBadgeProps],
    props: {
        productTourDataAttribute: {
            type: String,
            default: undefined,
        },
        hidePadding: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['click'],
    computed: {
        badgeClasses() {
            return {
                'c-action-badge--active': this.isActive,
                'c-action-badge--inactive': !this.isActive,
                'c-action-badge--hide-count': !this.showCount,
                'c-action-badge--small': this.isSmall,
                'c-action-badge--hide-padding': this.hidePadding,
                'is-loading': this.isLoading,
            };
        },
    },
    methods: {
        badgeClicked() {
            if (!this.isLoading) {
                this.$emit('click');
            }
        },
    },
};
</script>

<style lang="scss">
    .c-action-badge {
        $this: &;

        box-sizing: content-box;
        cursor: pointer;
        height: $nu-spacer-4;
        width: $nu-spacer-4;
        padding: $nu-spacer-1;
        margin: 0;

        &--hide-padding {
            padding: 0;
        }

        @include breakpoint(medium) {
            padding: $nu-spacer-2;

            &--hide-padding {
                padding: 0;
            }
        }

        &,
        &__fill-container {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        @include non-touch-device {
            &:hover {
                #{$this}__fill-container {
                    border-color: $nu-primary;
                }
            }
        }

        &--small {
            height: 44px;
            width: 44px;

            #{$this}__fill-container {
                height: 28px;
                width: 28px;
            }
        }

         &--hide-count {
            height: $nu-spacer-4;
            width: $nu-spacer-4;

            #{$this}__fill-container {
                height: 100%;
                width: 100%;
                padding: 0;
                border-radius: 50%;
            }
        }

        &__icon,
        &__counter,
        &__minus {
            transition: all 0.2s ease;
        }

        &__counter {
            color: $nu-gray;
            margin-right: $nu-spacer-1;
        }

        &--active {
            #{$this}__fill-container {
                background-color: $nu-primary;
                border-color: $nu-primary;
            }

            #{$this}__counter {
                color: $nu-white;
            }

            #{$this}__icon {
                fill: $nu-white;
            }

            @include non-touch-device {
                &:not(.is-loading):hover {
                    #{$this}__icon {
                        display: none;
                    }

                    #{$this}__minus {
                        display: block;
                    }
                }
            }

            &__counter,
            &__minus {
                color: $nu-white;
            }

            &__icon {
                fill: $nu-white;
            }
        }

        &--inactive {
            #{$this}__fill-container {
                background-color: $nu-white;
            }
        }

        &.is-loading {
            cursor: default;

            #{$this}__counter {
                color: $nu-white;
            }

            #{$this}__fill-container {
                background-color: $nu-primary;
                border-color: $nu-primary;
            }

            #{$this}__icon {
                display: none;
            }

            #{$this}__loading-spinner {
                display: block;
            }
        }

        &__fill-container {
            height: $nu-spacer-4;
            border-radius: $nu-spacer-2;
            padding: 0 $nu-spacer-1pt5;
            border: 1px solid $nu-white;
            transition: all 0.2s ease;
        }

        &__loading-spinner {
            display: none;
            color: $nu-gray--light;
        }

        &__minus {
            display: none;
            width: $nu-spacer-2;
            height: 1px;
            background: $nu-white;
        }
    }
</style>
