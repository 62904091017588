export default {
    props: {
        icon: {
            type: String,
            default: 'global--heart-unfilled',
        },
        count: {
            type: Number,
            default: 0,
        },
        showCount: {
            type: Boolean,
            default: false,
        },
        isActive: {
            type: Boolean,
            required: false,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
        isSmall: {
            type: Boolean,
            default: false,
        },
    },
};
